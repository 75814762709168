<template>
  <v-snackbar v-model="open" :color="color" :timeout="destroyTime">
    {{ notification.message }}
    <template v-slot:action="{ attrs }">
      <v-btn
        v-bind="attrs"
        :color="btnColor"
        small
        text
        class="mx-3"
        @click="open = false"
      >
        {{ btnLabel }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions } from 'vuex';
import { setTimeout, clearTimeout } from 'timers';

export default {
  name: 'NotificationSnackbar',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
      destroyTime: 5000,
      open: true,
    };
  },
  computed: {
    color() {
      return this.notification.type || 'info';
    },
    btnLabel() {
      return this.notification.btnLabel || 'Close';
    },
    btnColor() {
      return this.notification.btnColor || '';
    },
  },
  mounted() {
    this.timeout = setTimeout(() => {
      this.remove(this.notification.id);
    }, this.destroyTime);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: mapActions('notification', ['remove']),
};
</script>
